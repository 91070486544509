import moment from "moment";
import { getAssetPath } from "./fileUploader";
import { BYPASS_POST_VALIDATION_FOR_EMAILS } from "./constants";
import packageJson from "../../package.json";
import { CompanyMarkup, JobMarkup } from "../../types/next-auth";

export const formatTimeAgo = (date: Date) => {
  const currentDate = moment();
  const postDate = moment(date).utc();

  const diffInDays = currentDate.diff(postDate, "days");
  const diffInMonths = currentDate.diff(postDate, "months");
  const diffInYears = currentDate.diff(postDate, "years");

  if (diffInDays < 30) {
    return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
  } else if (diffInMonths < 12) {
    return `${diffInMonths} ${diffInMonths === 1 ? "month" : "months"} ago`;
  } else {
    return `${diffInYears} ${diffInYears === 1 ? "year" : "years"} ago`;
  }
};

export function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}

export function formatDate(
  date: moment.MomentInput,
  format: string = "DD/MM/YYYY"
) {
  if (!date) return "";

  return moment(date).utc().format(format);
}

export function generateSlug(title: string): string {
  const baseSlug = title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]+/g, "-")
    .replace(/-+/g, "-")
    .replace(/^-+|-+$/g, "");

  const uniquePart = Date.now().toString(36);

  const slug = `${baseSlug}-${uniquePart}`;

  return slug;
}

export function truncateString(
  inputString: string,
  maxLength: number = 20
): string {
  if (inputString?.length <= maxLength) {
    return inputString;
  } else {
    return inputString?.substring(0, maxLength - 3) + "...";
  }
}

export function removeHtmlTags(inputString: string) {
  if (!inputString) return "";
  return inputString.replace(/<\/?[^>]+(>|$)/g, " ").replace(/\s+/g, " ");
}

export function generateUniqueId() {
  const timestamp = new Date().getTime(); // Current timestamp in milliseconds
  const randomNum = Math.floor(Math.random() * 10000); // Random number between 0 and 9999
  const uniqueId = `${timestamp}${randomNum}`; // Combine timestamp and random number
  return uniqueId;
}

export function updateSearchParams(type: string, value: string) {
  const searchParams = new URLSearchParams(window.location.search);

  searchParams.set(type, value);

  const newPathname = `${window.location.pathname}?${searchParams.toString()}`;

  return newPathname;
}

export function convertToBooleanObject(keys: string[]) {
  type ResultType = {
    [key: string]: boolean;
  };

  const result: ResultType = {};

  keys.forEach((key) => {
    result[key] = true;
  });

  return result;
}

type ObjectWithStringValues = { [key: string]: string | number };

export function objectToQueryString(obj: ObjectWithStringValues): string {
  return Object.keys(obj)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(obj[key]))}`
    )
    .join("&");
}

export const convertToSearchParamsObject = (
  searchParams: URLSearchParams,
  options?: { exclude: string[] }
) => {
  const searchParamsObject: any = {};
  searchParams.forEach((value, key) => {
    if (!options?.exclude?.includes?.(key)) searchParamsObject[key] = value;
  });

  return searchParamsObject;
};

export const generateAvatarUrl = ({
  name = "",
  id = "",
  format = "png",
  size = 200,
}) => {
  const avatarName = `${name?.[0]}_${id}`;
  return `https://ui-avatars.com/api/?background=random&name=${avatarName}&length=1&size=${size}&format=${format}`;
};

export const getProfileImage = ({
  name = "",
  id = "",
  format = "png",
  size = 200,
  image = "",
}) => {
  return image
    ? getAssetPath(image || "")
    : generateAvatarUrl({
        name,
        id,
        format,
        size,
      });
};

export const getCoverImage = ({
  name = "",
  id = "",
  format = "png",
  size = 200,
  image = "",
}) => {
  return image
  ? getAssetPath(image || "")
  : `${process.env.NEXT_PUBLIC_DO_SPACES_ASSET_URI}/uploads/images/default-avatar.jpg`
};

export function convertObjectToArrayOptions(obj: Record<string, any>) {
  return Object.entries(obj).map(([value, name]) => ({ name, value }));
}

export function isAllowedJobPost(
  data: {
    title: string;
    description: string;
    benefits: string;
  },
  employerEmail: string
) {
  const description = removeHtmlTags(data.description);
  const benefits = removeHtmlTags(data.benefits);

  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  const phoneRegex =
    /\b[\+]?[(]?\d{1,3}[)]?[-\s\.]?[\d]{1,3}[-\s\.]?\d{3,}[-\s\.]?\d{4}\b/m;

  // Check if email or phone exists in title, description, or benefits
  if (
    BYPASS_POST_VALIDATION_FOR_EMAILS.includes(employerEmail || "")
      ? false
      : emailRegex.test(data.title) ||
        phoneRegex.test(data.title) ||
        emailRegex.test(description) ||
        phoneRegex.test(description) ||
        emailRegex.test(benefits) ||
        phoneRegex.test(benefits)
  ) {
    return false;
  }
  return true;
}

export const getTranslationsQuery = (
  args: any,
  lang: string,
  keys: string[]
) => {
  const argsCopy: any = { ...args };
  const select: any = {};

  keys.forEach((key) => {
    select[key] = true;
  });

  const translationsQuery = {
    where: {
      lang,
    },
    select,
  };

  if (!argsCopy.select) {
    if (!argsCopy.include) argsCopy.include = {};

    if (!argsCopy.include?.translations)
      argsCopy.include.translations = translationsQuery;
  } else if (!argsCopy.include?.translations) {
    argsCopy.select.translations = translationsQuery;
  }

  return argsCopy;

  // const countries = await query(args);

  // return countries.map((country) => ({
  //   ...country,
  //   country: country?.translations?.[0]?.country || country?.country,
  //   nationality:
  //     country?.translations?.[0]?.nationality || country?.nationality,
  // }));
};

export const getTranslatedRecords = (records: any[], keys: string[]) => {
  return records.map((record) => {
    const updatedRecord = { ...record };

    keys.forEach((key) => {
      updatedRecord[key] = record?.translations?.[0]?.[key] || record?.[key];
    });

    return {
      ...updatedRecord,
    };
  });
};

export function detectLanguage(text: string) {
  // Regular expressions for Arabic and English characters
  var arabicPattern = /[\u0600-\u06FF]/;
  var englishPattern = /[a-zA-Z]/;

  // Check if the text contains Arabic characters
  if (arabicPattern.test(text)) {
    return "ar";
  }

  // Check if the text contains English characters
  if (englishPattern.test(text)) {
    return "en";
  }

  // If no Arabic or English characters found, return "Unknown"
  return "Unknown";
}

export function calculateAge(birthDate?: Date | null): number | null {
  if (!birthDate) return null;
  const currentDate: moment.Moment = moment();
  const years: number = currentDate.diff(birthDate, "years");
  return years;
}

export function applyDiscount(price: number, discountPercentage: number) {
  if (discountPercentage < 0 || discountPercentage > 100) {
    throw new Error("Discount percentage must be between 0 and 100.");
  }

  const discountAmount = (price * discountPercentage) / 100;
  const discountedPrice = price - discountAmount;
  return discountedPrice;
}

export function calculateEndDate(startDate: Date, numberOfMonths: number) {
  // Create a new Date object based on the provided start date
  const start = new Date(startDate);

  // Add the specified number of months to the start date
  const end = new Date(start.setMonth(start.getMonth() + numberOfMonths));

  return end;
}

export function calculateEndDateByDays(startDate: Date, numberOfDays: number) {
  const start = new Date(startDate);

  const end = new Date(start.setDate(start.getDate() + numberOfDays));

  return end;
}
export function getMonthsDifference(startDate: Date, endDate: Date) {
  // Parse the input dates
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Get the year and month components of the dates
  const startYear = start.getFullYear();
  const startMonth = start.getMonth();
  const endYear = end.getFullYear();
  const endMonth = end.getMonth();

  // Calculate the difference in years and months
  const yearDifference = endYear - startYear;
  const monthDifference = endMonth - startMonth;

  // Convert the difference to total months
  const totalMonths = yearDifference * 12 + monthDifference;

  return totalMonths;
}

export function convertToSlug(str: string) {
  // Convert to lower case
  str = str.toLowerCase();

  // Remove accents, swap ñ for n, etc
  str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  // Remove invalid characters
  str = str.replace(/[^a-z0-9 -]/g, "");

  // Replace spaces with hyphens
  str = str.replace(/\s+/g, "-");

  // Remove multiple hyphens
  str = str.replace(/-+/g, "-");

  // Trim hyphens from the start and end
  str = str.replace(/^-+|-+$/g, "");

  return str;
}

export const containsArabic = (text: string) => {
  const arabicRegex = /[\u0600-\u06FF]/;
  return arabicRegex.test(text);
};

export const generateCompanySchema = (company: CompanyMarkup) => {
  return {
    "@context": "https://schema.org/",
    "@type": "Organization",
    "name": company.companyName,
    "url": `${process.env.APP_URL}/companies/${company.slug}`,
    "logo": company.companyLogo
      ? `${process.env.APP_URL}/path/to/logo/${company.companyLogo}`
      : "",
    "description": company.description || "",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "", 
      "addressLocality": "",  
      "addressRegion": "",
      "postalCode": "",
      "addressCountry": "", 
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "",
      "contactType": "customer service",
    },
  };
};


export const generateJobSchema = (job: JobMarkup) => {
  return {
    "@context": "https://schema.org/",
    "@type": "JobPosting",
    "title": job.title,
    "description": job.description,
    "datePosted": job.createdAt,
    "validThrough": job.applyBefore,
    "employmentType": job.jobType,
    "hiringOrganization": {
      "@type": "Organization",
      "name": job.companyName,
      "sameAs": job.companyWebsite || "",
    },
    "jobLocation": {
      "@type": "Place",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": job.address.streetAddress || "",
        "addressLocality": job.address.city,
        "addressRegion": job.address.region || "",
        "postalCode": job.address.postalCode || "",
        "addressCountry": job.address.country,
      },
    },
    // "baseSalary": {
    //   "@type": "MonetaryAmount",
    //   "currency": job.salary.currency || "USD",
    //   "value": {
    //     "@type": "QuantitativeValue",
    //     "value": job.salary.value || 0,
    //     "unitText": "YEAR",
    //   },
    // },
  };
};

export function getAppVersion() {
  return packageJson.version;
}